import Image from '@/common/image';
import Button from '@/common/button';

interface Props {
    className?: string;
    rowReverse?: boolean;
    title?: string;
    description?: string;
    buttonText?: string;
    buttonLink?: string;
    phone?: boolean;
    imgSrc?: string;
    leftRedShadow?: boolean;
    rightRedShadow?: boolean;
}
function ImageDescriptionRow(props: Props) {
    return (
        <div className={`flex flex-col-reverse items-center ${props.className}`}>
            <div className="w-full xl:w-[75%]">
                <div className={`${props.rowReverse ? 'xl:pl-[96px] pl-0' : 'xl:pr-[96px] pr-0'}`}>
                    <p className="pb-8 DH2MH3 text-primary">{props.title}</p>
                    <p className="lg:pb-10 md:pb-5 DP1MP1">{props.description}</p>
                    <div className="flex pt-5 lg:pt-0  md:pt-0 ">
                        {props.buttonText && !props.phone && (
                            <Button
                                link={props.buttonLink}
                                text={props.buttonText}
                                className="bg-primary text-white"
                            />
                        )}
                        {props.buttonText && props.phone && (
                            <a href={`tel:+${props.buttonText.replace(/[^A-Z0-9]/gi, '')}`}>
                                <Button
                                    phone={props.phone}
                                    text={props.buttonText}
                                    className="bg-primary text-white"
                                />
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={`w-full borderRadius border-t-4 border-redbg mb-12 lg:mb-0 -mt-12 lg:mt-0 overflow-hidden max-w-[343px] sm:max-w-[400px] lg:max-w-[500px] ${
                    props.leftRedShadow && 'imageLeftShadow border-r-4'
                } ${props.rightRedShadow && 'imageRightShadow border-l-4'}`}
            >
                <Image filename={props.imgSrc} alt={props.imgSrc} />
            </div>
        </div>
    );
}
export default ImageDescriptionRow;
