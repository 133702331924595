import ImageDescriptionRow from '@/common/image-description-row';
import { AltLayout } from '../layouts/AltLayout';
import Team from '@/common/team';
import { SEO } from '@/common/seo';

const OurTeam: Page = () => (
    <AltLayout
        heroButtonText="Get In Touch With Us"
        heroImg="hero-team.jpg"
        label="Our Team"
        title="Meet Our Qualified Staff"
        description="We have highly skilled dental assistants and office managers who are always available at your disposal in our office"
    >
        <div className="my-[120px] max-w-[1920px] ">
            <ImageDescriptionRow
                title="Dr Rick Zapata"
                description="Dr. Zapata firmly believes that superior quality and patient satisfaction are the fundamentals of excellence in dentistry. He takes pride in maintaining a personable atmosphere with a friendly vibrant staff. Dr. Zapata is dedicated to providing personalized care to all of his patients by at the forefront of dental techniques and using state of the art equipment. He consistently strives to create beautiful smiles and has a unique ability to connect with his patients on a personal level"
                className="xl:flex-row-reverse mb-[120px]"
                imgSrc="dr-rick-about.jpg"
                rightRedShadow
                rowReverse
            />
        </div>
        <div className="flex lg:grid gap-3 lg:gap-10 lg:grid-cols-2 xl:grid-cols-3 overflow-x-auto no-scrollbar mb-[120px]">
            <Team
                image="Flor.jpg"
                name="Flor Castillo"
                position="Office Manager"
                description="“Having the ability to help improve the smiles of each of our patients often in a very dramatic way leaves me great satisfaction. I love to have fun, work with and learn about our patients. This job is really interesting, each patient has a different need and each case is different. The combination of working so closely with patients and the incredible opportunity to learn and grow makes this the perfect job for me” "
            />
            <Team
                image="Vanessa.jpg"
                name="Vanessa Escobedo"
                position="Front Office"
                description="“I love dentistry because it is challenging and interesting to see the transformation of smiles and the satisfaction of patients especially after they see the final results. As a funny, compassionate, curious, and organized person, I strive to exceed patients’ expectations as I believe that patients are the single most important asset a dental office can have”"
            />
            <Team
                image="Ruby.jpg"
                name="Ruby Suarez"
                position="Dental Assistant"
                description="“Coming to the dentist can be scary and overwhelming. After being in the dental field for about 6 years, I feel that I provide excellent patient care and understanding as I love to treat every patient as if they were one of my own family members. A passion of mine is to educate patients on the importance of oral health” "
            />
            <Team
                image="Nelly.jpg"
                name="Nelly Guiterrez"
                position="Dental Assistant"
                description="“I have been in the dental field for 18 years now and I have recently moved from Chicago to Arizona. I am super friendly and outgoing and love dentistry because I find it to be an art. Part of my position is to get to make patients temporary crowns and be apart of their transformation process”"
            />
            <Team
                image="Liz.jpg"
                name="Liz Meza"
                position="Dental Assistant"
                description="“I love dentistry because I get to interact with patients while taking X-Rays. Its the reaction to their teeth transformation that really brings me joy. I am passionate about educating patients on how to care for their teeth because it is the key to good oral health. Currently, I am continuing my education to become a hygienist”"
            />
            <Team
                image="Arturo.jpg"
                name="Arturo Rodriguez"
                position="Dental Assistant"
                description="“Since I started working for Zapata Dental I realized that there will always be a teacher in or out of school to help guide you. He’s taught me so much such as focusing on what I want and to never settle for less. He is a great and caring person as he strives to give his patients the best quality service. One thing about him is that he walks into work every morning with a smile on his face. Working for him is such an honor” "
            />
        </div>
    </AltLayout>
);

export default OurTeam;

export const Head = () => (
    <SEO
        title="Team Zapata Dental - Phoenix, Arizona - Zapata Dental"
        description="Dr. Zapata firmly believes that superior quality and patient satisfaction are the fundamentals of excellence in dentistry."
        pathname="/about/"
    />
);
