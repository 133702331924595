import Image from '@/common/image';
interface Props {
    name?: string;
    image?: string;
    description?: string;
    position?: string;
}
const Team = ({ name, description, image, position }: Props) => {
    return (
        <div className="lg:min-w-[100%] min-w-[315px]  border-2 border-lightbrown  rounded-lg">
            <Image className="m-6 rounded-lg" filename={image} alt={image} />
            <h1 className="DH4MH3 flex justify-center items-center pt-4">{position}</h1>
            <p className="DP1MP1 text-lightbrown flex justify-center items-center ">{name}</p>
            <div className="mx-4 py-4 lg:mx-8 lg:py-[25px]">
                <p className="DP2MP1  text-grayT">{description}</p>
            </div>
        </div>
    );
};

export default Team;
